import React from "react"
import { Link } from "react-router-dom"

const Index = () => {
  return (
    <div className="App">
      <div>
        <Link to="/register">
          Formulário
        </Link>
      </div>
      <div>
        <Link to="/table">
          Tabela
        </Link>
      </div>
      <div>
        <Link to="/iframe">
          Iframe
        </Link>
      </div>
    </div>
  )
}

export default Index