import React from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Index from "."
import RegisterCustomer from "./register/register"
import TableCustomers from "./tableCustomers/tableCustomers"
import SearchGoogle from "./google/google"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />
  },
  {
    path: "/register",
    element: <RegisterCustomer />
  },
  {
    path: "/table",
    element: <TableCustomers />
  },
  {
    path: "/iframe",
    element: <SearchGoogle />
  },
])

const Routes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default Routes