import React, { useState } from 'react'
import * as cnpj from "@fnando/cnpj"
import rocket from '../../assets/rocket.svg'
import styles from './styles.module.scss'

function RegisterCustomer() {
  const [status, setStatus] = useState("")
  const [errors, setErrors] = useState<string[]>([])
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const errors: string[] = []
    // @ts-expect-error
    const elements: { [key: string]: HTMLFormElement } = event.target.elements
    const cnpjValid = cnpj.isValid(elements.cnpj.value)
    const cepValid = /^[0-9]{5}\-?[0-9]{3}$/.test(elements.zipCode.value)
    if (!cnpjValid) errors.push("CNPJ")
    if (!cepValid) errors.push("CEP")
    const status = errors.length > 0 ? "error" : "success"
    setStatus(status)
    setErrors(errors)
  }
  return (
    <div className="App">
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.text}>
            <p>Cadastre seus clientes</p>
          </div>
          <div className={styles.image}>
            <img src={rocket} />
          </div>
        </div>
        <div className={styles.right}>
          <form onSubmit={onSubmit}>
            <div className={styles.form}>
              <div className={styles.inputs}>
                <input className={styles.all} name="name" placeholder="Nome Fantasia" required />
              </div>
              <div className={styles.inputs}>
                <input className={styles.meio} name="companyName" placeholder="Razão Social" required />
                <input className={styles.meio} name="cnpj" placeholder="CNPJ" required />
              </div>
              <div className={styles.inputs}>
                <input className={styles.all} name="email" placeholder="Email" required />
              </div>
              <div>
                <div className={styles.inputs}>
                  <input className={styles.all} name="address" placeholder="Rua" required />
                </div>
                <div className={styles.inputs}>
                  <input className={styles.meio} name="number" placeholder="Número" required />
                  <input className={styles.meio} name="district" placeholder="Bairro" required />
                </div>
                <div className={styles.inputs}>
                  <input className={styles.meio} name="complement" placeholder="Complemento" required />
                  <input className={styles.meio} name="zipCode" placeholder="CEP" required />
                </div>
              </div>
            </div>
            {status === "success" && (
              <div className={styles.success}>
                Campos preenchidos com sucesso
              </div>
            )}
            {status === "error" && (
              <div className={styles.error}>
                Algumas informações não estão corretas, verifique os campos a seguir:
                {errors.join(", ")}.
              </div>
            )}
            <div className={styles.submit}>
              <input type="submit" value="Cadastrar" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RegisterCustomer
